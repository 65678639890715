import React from "react";

function Footer() {
    return (
      <section className="footer">
        <p>&copy; All Rights Reserved Bluebug Software.</p>
      </section>
    );
  }

export default Footer