import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Background from './Background';
import Expertise from './Expertise';
import Services from './Services';
import Sidebar from './Sidebar';
import Footer from './Footer';
import AboutUs from './AboutUs';
import Blogs from './Blogs';
import BlogContent from './BlogContent';
import CareerPage from './CareerPage';
import './App.css';
import bug from './img/bug.webp'
import logo from './img/logo.webp';
import Contact from './Contact';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      setTimeout(() => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [location]);

  return (
    <div className="app-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className='main'>
        <Background />
        <div className="main-container">
          <Sidebar />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/blogpost" element={<BlogContent />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function MainContent() {
  return (
    <main className="main-content glass-effect">
      <Home />
      <Motto />
      <Services />
      <Expertise />
      <Testimonials />
      <Contact />
      <Footer />
    </main>
  );
}
function Home() {
  return (
    <section className="home">
      <p>Welcome to Bluebug Software!</p>
      <h1>Your Vision, Our Code.</h1>
      <div className='btn'>
        Let's Build Together &rarr;
      </div>
      <h5>scroll down &darr;</h5>
    </section>
  );
}

function Motto() {
  return (
    <section className="motto">
      <div className='motto-left'>

      <h2>Our Motto</h2>
      <h4>INNOVATE.</h4>
      <h4>INTEGRATE.</h4>
      <h4>INSPIRE.</h4>
      </div>
      <div className='motto-right'>
        <img src={bug} alt='motto-img'></img>
      </div>
      {/* Add your company motto */}
    </section>
  );
}





function Testimonials() {
  return (
    <section className="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonial-carousel">
        <div className="testimonial-track">
          <div className="testimonial-slide">
            <p>"Bluebug Software transformed our business with their tailored CRM solution. Their expertise and dedication are unmatched!"</p>
            <h4>John Doe</h4>
            <p>CEO, Tech Innovations Inc.</p>
          </div>
          <div className="testimonial-slide">
            <p>"The website Bluebug created for us is not only beautiful but also drives conversions. We couldn't be happier with the results!"</p>
            <h4>Jane Smith</h4>
            <p>Marketing Director, E-commerce Solutions</p>
          </div>
          <div className="testimonial-slide">
            <p>"Their mobile app development skills are top-notch. Our app has received rave reviews from users. Thank you, Bluebug!"</p>
            <h4>Mike Johnson</h4>
            <p>Founder, StartUp Mobile</p>
          </div>
          {/* Add more testimonial slides as needed */}
        </div>
      </div>
    </section>
  );
}



export default App;