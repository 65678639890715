import React, { useState } from 'react';
import './Career.css'; // Ensure you have this CSS file for styling

const CareerPage = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    address: '',
    message: ''
  });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.fullname) formErrors.fullname = "Fullname is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Valid email is required.";
    if (!formData.message) formErrors.message = "Message is required.";
    if (!file) formErrors.file = "CV file is required.";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append('fullname', formData.fullname);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('message', formData.message);
    formDataToSend.append('cv', file);

    try {
      const response = await fetch('https://bluebugv2.bluebugsoft.com/career_submission.php', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.text();
      alert('Application submitted successfully!');
      
      // Reset form fields
      setFormData({
        fullname: '',
        email: '',
        phone: '',
        address: '',
        message: ''
      });
      setFile(null);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    }
  };

  return (
    <main className="main-content glass-effect">
      <section className="career">
        <div className="career-main">
          <h1>Join Bluebug Team.</h1>
          <h5>Specify Your Expertise and Desired Position In Message.</h5>

          <form onSubmit={handleSubmit}>
            <div className='careerform'>
              <div className="form-group">
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  placeholder="Your full name"
                  value={formData.fullname}
                  onChange={handleChange}
                  required
                />
                {errors.fullname && <p className="error">{errors.fullname}</p>}
              </div>

              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Your phone number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Your address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group upload">
                <label htmlFor="cv" className='cvupload'>Upload your CV here +</label><br />
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  required
                />
                {errors.file && <p className="error">{errors.file}</p>}
              </div>

              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write your message here..."
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>

              <div className="form-group">
                <button type="submit" className='btn'>Submit &rarr;</button>
              </div>
            </div>
          </form>
        </div>

        {/* Inline Popup */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              {/* <p>{popupMessage}</p> */}
              <button onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default CareerPage;
