import React, { useState } from 'react';
import ser1 from './img/ser1.webp';
import ser2 from './img/ser2.webp';
import ser3 from './img/ser3.webp';

const Services = () => {
  const [activeService, setActiveService] = useState(1); // Set default active service

  const handleMouseEnter = (index) => {
    setActiveService(index);
  };

  return (
    <section className="our-services">
      <h2>Our Services</h2>
      <div className="services">

        <div className="services-left">
          <div
            className="service-title"
            onMouseEnter={() => handleMouseEnter(1)}
          >
            Tailored Software Development
          </div>
          <div className={`service-description ${activeService === 1 ? 'active' : ''}`}>
            <p>Our tailored software solutions streamline your operations, improve efficiency, and support your business growth. From CRM systems to enterprise applications, we deliver robust software that meets your unique needs.</p>
            <a href='https://forms.gle/uuK6vcG3f1r56MhE9' className='btn'target='blank'>Get quote &rarr;</a>
          </div>
          <div
            className="service-title"
            onMouseEnter={() => handleMouseEnter(2)}
          >
            Website Development
          </div>
          <div className={`service-description ${activeService === 2 ? 'active' : ''}`}>
            <p>We build responsive, fast, and secure websites that represent your brand and engage your audience. Starting at just Rs. 15,000, our custom solutions ensure your business stands out in the digital world</p>
            <a href='https://forms.gle/uuK6vcG3f1r56MhE9' className='btn'target='blank'>Get quote &rarr;</a>
          </div>
          <div
            className="service-title"
            onMouseEnter={() => handleMouseEnter(3)}
          >
            App Development
          </div>
          <div className={`service-description ${activeService === 3 ? 'active' : ''}`}>
            <p>Stay connected with your customers 24/7 through innovative mobile apps. We design and develop user-friendly, feature-rich apps for both Android and iOS platforms to help you reach your target audience effectively.</p>
            <a href='https://forms.gle/uuK6vcG3f1r56MhE9' className='btn'target='blank'>Get quote &rarr;</a>
          </div>
        </div>
        <div className="services-right">
          <img
            src={ser1}
            alt="Tailored Software Development"
            className={`service-image ${activeService === 1 ? 'active' : ''}`}
          />
          <img
            src={ser2}
            alt="Website Development"
            className={`service-image ${activeService === 2 ? 'active' : ''}`}
          />
          <img
            src={ser3}
            alt="App Development"
            className={`service-image ${activeService === 3 ? 'active' : ''}`}
          />
        </div>
      </div>
    </section>
  );
}

export default Services;
